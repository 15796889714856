// Generated by ReScript, PLEASE EDIT WITH CARE

import * as U from "../utils/U.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.mjs";
import * as FramerMotion from "framer-motion";
import * as JsxRuntime from "react/jsx-runtime";

function Text$Descriptive(props) {
  var text = props.text;
  return JsxRuntime.jsxs("p", {
              children: [
                text !== undefined ? text : null,
                Core__Option.getOr(props.children, null)
              ],
              className: "text-sm text-gray-500"
            });
}

var Descriptive = {
  make: Text$Descriptive
};

function Text$LargeTitle(props) {
  var __color = props.color;
  var color = __color !== undefined ? __color : "Black";
  var tmp;
  tmp = color === "Black" ? "text-gray-900" : "text-white";
  return JsxRuntime.jsx("h1", {
              children: JsxRuntime.jsx(FramerMotion.motion.span, {
                    children: Caml_option.some(props.title),
                    layoutId: props.layoutId
                  }),
              className: U.tw([
                    "text-2xl font-bold leading-7 sm:text-3xl font-serif whitespace-nowrap",
                    tmp
                  ])
            });
}

var LargeTitle = {
  make: Text$LargeTitle
};

function Text$Title(props) {
  var __color = props.color;
  var color = __color !== undefined ? __color : "Default";
  var color$1;
  color$1 = color === "Default" ? "text-gray-900" : "text-red-600";
  return JsxRuntime.jsx("h3", {
              children: JsxRuntime.jsx(FramerMotion.motion.span, {
                    children: Caml_option.some(props.title),
                    layoutId: props.layoutId
                  }),
              className: U.tw([
                    "text-lg leading-6 font-medium",
                    color$1
                  ])
            });
}

var Title = {
  make: Text$Title
};

export {
  Descriptive ,
  LargeTitle ,
  Title ,
}
/* framer-motion Not a pure module */
