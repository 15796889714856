// Generated by ReScript, PLEASE EDIT WITH CARE


function parse(str) {
  switch (str) {
    case "Custom" :
        return "Custom";
    case "ExistingCampaign" :
        return "ExistingCampaign";
    default:
      return ;
  }
}

function serialize(t) {
  return t;
}

var defaultValue = "Custom";

export {
  parse ,
  serialize ,
  defaultValue ,
}
/* No side effect */
